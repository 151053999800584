.orderFlex {
  display: flex;
  align-items: center;
  gap: 12px;
}

.customerLink {
  color: #263682 !important;
  text-align: center;
  font-size: 18px;
  font-family: roobert_trialmedium;
}
.orderReviewLeft {
  width: 100%;
  padding: 20px 30px 30px !important;
  border-radius: 20px;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.1));
  height: calc(100vh - 50px);
}
.orderReviewRight {
  height: calc(100vh - 50px);
  border-radius: 20px;
}
.orderReviewLeft .scheduleModal {
  height: 100%;
}
.invoice_board {
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  color: #263682;
  box-shadow: -7px 19px 30px 20px #344f7514;
  height: calc(100vh - 166px);
  overflow-y: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.invoice_board .scheduleModal {
  height: 100%;
}
.invoice_board_header {
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 2.5px dotted #d7deff;
  padding: 24px;
}
.orderReviewLeft .payment_details {
  padding: 20px 24px;
  border-bottom: 2.5px dotted #d7deff;
}
.invoiceBottomParent{
  border-top: 2.5px dotted #d7deff;
}
.invoice_board_header .ib_title {
  font-size: 24px;
  font-weight: 650;
  margin-bottom: 15px;
}

.paymentDetailInner {
  margin-bottom: 14px;
}
.payment_details_Box .paymentDetailInner:nth-last-child(-n+3) {
  margin: 0;
}
.payment_details_Box{
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
}

.paymentSubTotal {
  margin-bottom: 14px;
}
.payment_details .paymentSubTotal:last-child {
  margin-bottom: 0;
}
.granTotalbox {
  padding: 19px 24px;
}
.granTotalbox .totalCountNumber {
  padding: 11px 39px;
  background: #f5f6fc;
  font-size: 18px;
  font-weight: 500;
  border-radius: 30px;
  text-align: center;
  width: max-content;
}
.invoice_board_header p,
.invoice_board_header span {
  font-size: 10px;
  font-weight: 500;
  color: #263682;
}


.grand_total span {
  font-size: 1.2rem;
  color: #fff;
  background-color: #28a745; /* Green color for total */
  padding: 10px 20px;
  border-radius: 5px;
}

.title_and_qr {
  text-align: center;
}

.title_and_qr .invoiceJobrLogo {
  margin-bottom: 16px;
}

.orderReviewDataTableBox{
  padding: 19px 24px;
}
.orderReviewDataTable{
  padding: 8px 0;
}

.orderDetailArticle{
  position: relative;
  top: 12px;
}
.lineStepImg {
  margin: 8px 0;
}
.positionImg {
  display: flex;
  flex-direction: column;
  align-items: center;
}