/* Style for the overlay background */
.loading-overlay {
    position: absolute; /* Fixes the overlay on top of other content */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Light black background with opacity */
    display: flex;
    justify-content: center; /* Centers the spinner horizontally */
    align-items: center; /* Centers the spinner vertically */
    z-index: 1050; /* Ensures the overlay appears above other content */
  }
  
  /* Optionally, if you want to hide the spinner initially */
  .loading-overlay {
}
  