/* next and prev btn */
.nextPagebtn, .prevPagebtn{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 36px;
    height: 36px;
    border: 1px solid #d7deff;
}
.nextPagebtn.disableNextbtn, .prevPagebtn.disablePrevbtn{
    background: #f5f6fc;
   
}
/* ---------delivery options---------- */
.deliveryStatusOptions {
    border-radius: 20px;
    padding: 3px 6px;
    font-size: 12px;
    font-family: "roobert_trialmedium";
    display: inline-block;
  }
.deliveryStatusOptions.storeImgbtn{
    background: #f5f6fc;
    border: 1px solid #f5f6fc;
    color: #4659b5;
}
.deliveryStatusOptions.pickupCartbtn{
    background:#FFF7D7;
    border: 1px solid #FFF7D7;
    color: #DC6803;
}
.deliveryStatusOptions.driverSolidbtn{
    background: #F5EDFF;
    border: 1px solid #F5EDFF;
    color: #7233C2;
}
.deliveryStatusOptions.shippingstatusbtn{
    background: #EFFBFF;
    border: 1px solid #EFFBFF;
    color: #47B0D6;
}
/* delivery status */
.deliveryStatus_{
/* height: 20px; */
padding: 2px 9px 3px 9px;
gap: 4px;
border-radius: 20px ;
display: flex;
align-items: center;
justify-content: center;

}
.deliveryStatus_.preparigingStatus{
    background: #F7F9FF;
    border: 1px solid #F7F9FF;
    color: #636E9F;
}
.deliveryStatus_.revieworderStatus{
    background: #263682;
    border: 1px solid #263682;
    color: #E4E6F2;
}
.deliveryStatus_.driverAssignedStatus{
    background: #914BEB;
    border: 1px solid #914BEB;
    color: #F5EDFF;
}
.deliveryStatus_.pickupStatus{
    background: #58C2E8;
    border: 1px solid #58C2E8;
    color: #EFFBFF;
}

.deliveryStatus_.completedStatus,
.deliveryStatus_.acceptedStatus
{
    background: #ECFDF3;
    border: 1px solid #ECFDF3;
    color: #039855;
}
.deliveryStatus_.cancelledStatus{
    background: #FEF3F2;
    border: 1px solid #FEF3F2;
    color: #D92D20;
}
.deliveryStatus_.returnedStatus,
.deliveryStatus_.rejectedStatus
{
    background: #6F7C88;
    border: 1px solid #6F7C88;
    color: #E4EBF1;
}

